<template>
  <div class="taobao">
    <img
      class="banner"
      src="@/assets/images/taobao-banner.png">
    <div class="nav">
      <ul class="list">
        <li
          v-for="item in navs"
          :key="item.id"
          :class="[
            'item',
            {
              'active': item.id === materialId
            }
          ]"
          @click="onSwitchNav(item.id, item.type)">{{ item.title }}</li>
      </ul>
    </div>
    <div
      class="goods"
      ref="goodsDom"
      @scroll="onLoadGoods">
      <ul class="list">
        <li
          v-for="item in goods"
          :key="item.goodsId"
          class="item">
          <img
            class="cover"
            :src="item.imgUrls ? item.imgUrls[0] : ''">
          <div class="content">
            <div class="name">{{ item.title }}</div>
            <div class="coupon">
              <div
                v-if="item.originalPrice - item.price > 0"
                class="discount">{{ '¥' + (item.originalPrice - item.price).toFixed(2) }}</div>
              <div
                v-if="item.presaleDiscountFeeText"
                class="discount presale">{{ item.presaleDiscountFeeText }}</div>
            </div>
            <div class="special-price">{{ item.originalPrice - item.price > 0 ? '券后价：' : '' }}<span class="unit">¥</span><span class="number">{{ item.price }}</span><span v-if="item.originalPrice > item.price" class="origin-price"><span class="unit">¥</span>{{ item.originalPrice }}</span></div>
            <button
              class="buy"
              @click="onCopyCode(item.goodsId)">去抢购</button>
          </div>
        </li>
      </ul>
    </div>
    <transition name="modal-fade">
      <div
        v-show="visible"
        class="modal">
        <transition name="scale-fade">
          <div
            v-show="visible"
            class="container">
            <img
              class="image"
              src="@/assets/images/1111/9.png">
            <button
              class="button"
              @click="onOpenTaoBao"/>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { Res } from '@/api'
import { Goods, Tpwd } from '@/api/goods'
import { copy } from '@/utils'
import { Vue } from 'vue-class-component'

interface Nav {
  id: string
  title: string
  type: number
}

export default class Login extends Vue {
  navs: Array<Nav> = [{
    id: '20150318020001157',
    title: '双11主会场',
    type: 0
  }, {
    id: '1604142741754',
    title: '薇娅&李佳琦推荐',
    type: 0
  }, {
    id: '20150318020001158',
    title: '双11预售会场',
    type: 0
  }, {
    id: '20150318020000462',
    title: '聚划算百亿补贴',
    type: 0
  }, {
    id: '28026',
    title: '实时热销榜单',
    type: 1
  }]

  materialId: string = this.navs[0].id
  goods: Array<Goods> = []
  page: number = 1
  size: number = 20
  lock: boolean = true
  visible: boolean = false

  onSwitchNav (id: string, type: number): void {
    this.materialId = id
    if (type === 0) {
      this.getGoods()
    } else {
      this.getMaterial()
    }
    (this.$refs.goodsDom as HTMLDivElement).scrollTop = 0
    this.$DR.pageReportFn({
      subModule: 'HGXM_TaobaoPage_SwitchNav',
      content: id
    })
  }

  async getGoods (page: number = 1): Promise<void> {
    if (this.lock) {
      this.lock = false
      const res: Res<Array<Goods>> = await this.$api.goods.taobaoActivityGoodsList(this.materialId, page, this.size)
      if (res.code === 200 && res.data) {
        if (page === 1) {
          this.goods = res.data
        } else {
          this.goods = this.goods.concat(res.data)
        }
        this.page = page
      }
      this.lock = true
    }
  }

  async getMaterial (page: number = 1): Promise<void> {
    if (this.lock) {
      this.lock = false
      const res: Res<Array<Goods>> = await this.$api.goods.taobaoMaterialOptional(this.materialId, page, this.size)
      if (res.code === 200 && res.data) {
        if (page === 1) {
          this.goods = res.data
        } else {
          this.goods = this.goods.concat(res.data)
        }
        this.page = page
      }
      this.lock = true
    }
  }

  onLoadGoods (e: Event): void {
    const target: HTMLDivElement = e.target as HTMLDivElement
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight - 10) {
      const nav: Nav | undefined = this.navs.find((item: Nav): boolean => item.id === this.materialId)
      if (nav) {
        const type: number = nav.type
        const page: number = this.page + 1
        if (type === 0) {
          this.getGoods(page)
        } else {
          this.getMaterial(page)
        }
      }
    }
  }

  async onCopyCode (goodsId: number): Promise<void> {
    const res: Res<Tpwd> = await this.$api.goods.taobaoGenerateShareGoods(goodsId)
    if (res.code === 200 && res.data) {
      copy(res.data.longTpwd)
      this.$toast({
        message: '口令复制成功，请到淘宝APP打开',
        duration: 2500
      })
    } else {
      this.$toast('生成淘口令失败')
    }
    this.$DR.pageReportFn({
      subModule: 'HGXM_TaobaoPage_CopyCode',
      content: goodsId
    })
  }

  onOpenTaoBao (): void {
    copy('8淘宝超级红包第二弹，復制整条，去🍑桃寶￥CQ4jcQHhzzN￥领取，金额超高！上车啰！也可分享他人哦/')
    this.$toast({
      message: '口令复制成功，请到淘宝APP打开',
      duration: 2500
    })
    this.visible = false
    this.$DR.pageReportFn({ subModule: 'HGXM_TaobaoPage_TaoBaoOpen' })
  }

  created () {
    this.getGoods()
  }

  mounted (): void {
    this.visible = true
  }
}
</script>

<style lang="scss" scoped>
@keyframes modal-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes modal-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes scale-fade-in {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes scale-fade-out {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0, 0);
  }
}

.modal-fade-enter-active {
  animation: modal-fade-in 1000ms;
}

.modal-fade-leave-active {
  animation: modal-fade-out 1000ms;
}

.scale-fade-enter-active {
  animation: scale-fade-in 1000ms;
}

.scale-fade-leave-active {
  animation: scale-fade-out 1000ms;
}
.taobao {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #cf3444;
  .banner {
    flex-shrink: 0;
    display: block;
    width: 100%;
  }
  .nav {
    flex-shrink: 0;
    overflow-x: scroll;
    background-color: #cf3444;
    .list {
      display: flex;
      .item {
        flex-shrink: 0;
        height: 50px;
        font-size: 14px;
        color: rgba(#fff, 0.8);
        border-bottom: 3px solid transparent;
        line-height: 50px;
        padding: 0 20px;
        &.active {
          color: #fff;
          font-size: 16px;
          border-bottom: 3px solid #fff;
        }
      }
    }
  }
  .goods {
    flex: auto;
    overflow-y: scroll;
    margin-top: 10px;
    .list {
      .item {
        display: flex;
        padding: 10px;
        background-color: #fff;
        border-radius: 6px;
        margin: 10px 10px 0 10px;
        &:first-child {
          margin-top: 0;
        }
        .cover {
          flex-shrink: 0;
          display: block;
          width: 90px;
          height: 90px;
          border-radius: 6px;
        }
        .content {
          flex: auto;
          margin-left: 10px;
          position: relative;
          .name {
            height: 44px;
            font-size: 14px;
            line-height: 22px;
            color: #333;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
          }
          .coupon {
            display: flex;
            height: 16px;
            .discount {
              min-width: 30px;
              height: 16px;
              font-size: 10px;
              color: #fff;
              background-color: #e60029;
              border-radius: 3px;
              text-align: center;
              line-height: 17px;
              padding: 0 5px;
              position: relative;
              margin-top: 5px;
              &:before,
              &:after {
                content: '';
                display: block;
                width: 5px;
                height: 5px;
                background-color: #fff;
                border-radius: 50%;
                position: absolute;
                top: 50%;
              }
              &:before {
                left: 0;
                transform: translate(-50%, -50%);
              }
              &:after {
                right: 0;
                transform: translate(50%, -50%);
              }
              &.presale {
                margin-left: 10px;
                background-color: #e69600;
              }
            }
          }
          .special-price {
            height: 20px;
            font-size: 12px;
            color: #e60029;
            line-height: 20px;
            margin-top: 5px;
            .unit {
              font-size: 10px;
            }
            .number {
              font-size: 18px;
            }
            .origin-price {
              color: #999;
              text-decoration: line-through;
              margin-left: 10px;
            }
          }
          .buy {
            display: block;
            min-width: 80px;
            height: 26px;
            padding: 0 5px;
            font-size: 12px;
            font-weight: 500;
            color: #fff;
            background-color: #e60029;
            border: 0;
            border-radius: 4px;
            line-height: 26px;
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }
      }
    }
  }
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.8);
    .container {
      position: relative;
      width: 375px;
      height: 667px;
      .image {
        display: block;
        width: 375px;
        height: 667px;
      }
      .button {
        display: block;
        width: 90px;
        height: 110px;
        background-color: transparent;
        border: 0;
        outline: none;
        position: absolute;
        top: 370px;
        left: 50%;
        margin-left: -45px;
      }
    }
  }
}
</style>
