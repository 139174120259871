
import { Res } from '@/api'
import { Goods, Tpwd } from '@/api/goods'
import { copy } from '@/utils'
import { Vue } from 'vue-class-component'

interface Nav {
  id: string
  title: string
  type: number
}

export default class Login extends Vue {
  navs: Array<Nav> = [{
    id: '20150318020001157',
    title: '双11主会场',
    type: 0
  }, {
    id: '1604142741754',
    title: '薇娅&李佳琦推荐',
    type: 0
  }, {
    id: '20150318020001158',
    title: '双11预售会场',
    type: 0
  }, {
    id: '20150318020000462',
    title: '聚划算百亿补贴',
    type: 0
  }, {
    id: '28026',
    title: '实时热销榜单',
    type: 1
  }]

  materialId: string = this.navs[0].id
  goods: Array<Goods> = []
  page: number = 1
  size: number = 20
  lock: boolean = true
  visible: boolean = false

  onSwitchNav (id: string, type: number): void {
    this.materialId = id
    if (type === 0) {
      this.getGoods()
    } else {
      this.getMaterial()
    }
    (this.$refs.goodsDom as HTMLDivElement).scrollTop = 0
    this.$DR.pageReportFn({
      subModule: 'HGXM_TaobaoPage_SwitchNav',
      content: id
    })
  }

  async getGoods (page: number = 1): Promise<void> {
    if (this.lock) {
      this.lock = false
      const res: Res<Array<Goods>> = await this.$api.goods.taobaoActivityGoodsList(this.materialId, page, this.size)
      if (res.code === 200 && res.data) {
        if (page === 1) {
          this.goods = res.data
        } else {
          this.goods = this.goods.concat(res.data)
        }
        this.page = page
      }
      this.lock = true
    }
  }

  async getMaterial (page: number = 1): Promise<void> {
    if (this.lock) {
      this.lock = false
      const res: Res<Array<Goods>> = await this.$api.goods.taobaoMaterialOptional(this.materialId, page, this.size)
      if (res.code === 200 && res.data) {
        if (page === 1) {
          this.goods = res.data
        } else {
          this.goods = this.goods.concat(res.data)
        }
        this.page = page
      }
      this.lock = true
    }
  }

  onLoadGoods (e: Event): void {
    const target: HTMLDivElement = e.target as HTMLDivElement
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight - 10) {
      const nav: Nav | undefined = this.navs.find((item: Nav): boolean => item.id === this.materialId)
      if (nav) {
        const type: number = nav.type
        const page: number = this.page + 1
        if (type === 0) {
          this.getGoods(page)
        } else {
          this.getMaterial(page)
        }
      }
    }
  }

  async onCopyCode (goodsId: number): Promise<void> {
    const res: Res<Tpwd> = await this.$api.goods.taobaoGenerateShareGoods(goodsId)
    if (res.code === 200 && res.data) {
      copy(res.data.longTpwd)
      this.$toast({
        message: '口令复制成功，请到淘宝APP打开',
        duration: 2500
      })
    } else {
      this.$toast('生成淘口令失败')
    }
    this.$DR.pageReportFn({
      subModule: 'HGXM_TaobaoPage_CopyCode',
      content: goodsId
    })
  }

  onOpenTaoBao (): void {
    copy('8淘宝超级红包第二弹，復制整条，去🍑桃寶￥CQ4jcQHhzzN￥领取，金额超高！上车啰！也可分享他人哦/')
    this.$toast({
      message: '口令复制成功，请到淘宝APP打开',
      duration: 2500
    })
    this.visible = false
    this.$DR.pageReportFn({ subModule: 'HGXM_TaobaoPage_TaoBaoOpen' })
  }

  created () {
    this.getGoods()
  }

  mounted (): void {
    this.visible = true
  }
}
