function createTextarea (text: string): HTMLTextAreaElement {
  const textarea: HTMLTextAreaElement = document.createElement('textarea')
  textarea.style.border = '0'
  textarea.style.padding = '0'
  textarea.style.margin = '0'
  textarea.style.opacity = '0'
  textarea.style.position = 'fixed'
  textarea.style.left = '-9999px'
  textarea.value = text
  textarea.setAttribute('readonly', '')
  document.body.appendChild(textarea)
  textarea.select()
  textarea.setSelectionRange(0, textarea.value.length)
  return textarea
}

function copyTextByTextarea (text: string): Promise<void> {
  return new Promise(function (resolve, reject) {
    // 解决苹果手机异步 execCommand 不生效的问题
    if (
      window.navigator.userAgent &&
      /\(i[^;]+;( U;)? CPU.+Mac OS X/.test(window.navigator.userAgent)
    ) {
      const button: HTMLButtonElement = document.createElement('button')
      button.style.border = '0'
      button.style.padding = '0'
      button.style.margin = '0'
      button.style.opacity = '0'
      button.style.position = 'fixed'
      button.style.left = '-9999px'
      button.addEventListener('click', () => {
        const textarea: HTMLTextAreaElement = createTextarea(text)
        try {
          document.execCommand('copy')
          document.body.removeChild(textarea)
          resolve()
        } catch (err) {
          reject(err)
        }
      })
      document.body.appendChild(button)
      setTimeout(() => {
        button.click()
        document.body.removeChild(button)
      })
    } else {
      const textarea: HTMLTextAreaElement = createTextarea(text)
      try {
        document.execCommand('copy')
        document.body.removeChild(textarea)
        resolve()
      } catch (err) {
        reject(err)
      }
    }
  })
}

export async function copy (text: string): Promise<void> {
  if (
    window &&
    window.navigator &&
    window.navigator.clipboard &&
    window.navigator.clipboard.writeText
  ) {
    try {
      await window.navigator.clipboard.writeText(text)
    } catch (err) {
      await copyTextByTextarea(text)
    }
  } else {
    await copyTextByTextarea(text)
  }
}

// export function copy (text: string): Promise<void> {
//   if (
//     window &&
//     window.navigator &&
//     window.navigator.clipboard &&
//     window.navigator.clipboard.writeText
//   ) {
//     return window.navigator.clipboard.writeText(text).catch(() => {
//       return copyTextByTextarea(text)
//     })
//   }
//   return Promise.resolve()
// }
